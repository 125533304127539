<template>
  <div class="px-5" id="page-reports">
    <h5 class="mb-6 ml-2">{{  $t('PAGES.REPORTS.REPORTS') }}</h5>

    <b-card>
      <ExportSalesButton />
      <ExportRegisteredMembersMonthButton class="mt-8" />
    </b-card>

    <b-card v-if="currentCompanyId === 'k0b3s' || currentCompanyId === 'sVkz2d'">
      <ExportUngReport1Button />
      <ExportUngReport2Button />
      <ExportUngReport3Button />
    </b-card>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import ExportSalesButton from '@/view/components/ExportSalesButton.vue';
import ExportUngReport1Button from '@/view/components/ExportUngReport1Button.vue';
import ExportUngReport2Button from '@/view/components/ExportUngReport2Button.vue';
import ExportUngReport3Button from '@/view/components/ExportUngReport3Button.vue';
import ExportRegisteredMembersMonthButton from '@/view/components/ExportRegisteredMembersMonthButton.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'reports',
  components: {
    ExportSalesButton,
    ExportUngReport1Button,
    ExportUngReport2Button,
    ExportUngReport3Button,
    ExportRegisteredMembersMonthButton
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
  },
  mounted() {

  },
  data() {
    return {
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {

    },
  },
  methods: {

  }
};
</script>
